@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

* {
	font-family: Montserrat, "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif !important;
}

.banner {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background: #222;
    color: #fff;
    padding: 15px 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    max-width: 300px;
    font-size: 14px;
  
    @media (max-width: 768px) {
      bottom: 10px;
      left: 10px;
      max-width: 50%;
      font-size: 10px;
    }
  }
  
  .title {
    margin: 0;
    padding-bottom: 20px;
    font-weight: bold;
    font-size: 18px;
  
    @media (max-width: 768px) {
      font-size: 12px;
      padding-bottom: 10px;
    }
  }
  
  .text {
    margin: 0;
    padding-bottom: 10px;
    line-height: 1.5;
  }
  
  .link {
    color: #0af;
    text-decoration: none;
  }
  
  .button {
    padding: 8px 16px;
    background-color: #0af;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  