@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

* {
	font-family: Montserrat, "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif !important;
}

.home-container {
	box-sizing: border-box;
}

.top-container {
    display: flex;
    align-items: center;
	background-image: url("../../assets/img/home/webp/unimake_solucoes_background.webp");
	background-position: left top;
    border-radius: 0px;
	width: 100vw;
	height: 100vh;
	padding: 0% 10% 0% 5%;
}

.image-home, .desc-home {
	flex: 1;
}

.image-home {
	padding: 0% 0% 0% 2%;
}

.desc-home {
	padding: 2% 0% 0% 10%;
}

.image-home img {
    width: 100%;
    height: 100%;
	margin-left: 5%;
}

.desc-home h1 {
	color: #FFF !important;
}

.desc-home h1, p, button {
    padding: 0% 5% 5% 0%;
	text-align: left;
	color: #FFF;
}

.desc-home h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 120%;
}

.desc-home p {
	font-size: 23px;
	line-height: 34.5px;
}

.desc-home button {
	font-size: 25px;
	font-weight: 700;
	padding: 10px 20px;
	border-radius: 50px;
	border: 4px solid white;
	background-color: transparent;
	color: white;
	cursor: pointer;
	transition: background-color 0.5s, color 0.5s, border-color 0.5s;
  
	&:hover {
	  background-color: white;
	  color: rgb(4, 35, 76) !important;
	  border-color: rgb(4, 35, 76) !important;
	}
  
}

.msg-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #FFF;
	padding: 8% 0% 8% 0%;
}

.msg-container p {
	width: auto;
	height: auto;
	color: #1474b6 !important;
	font-size: 28px;
	font-weight: 700;
	line-height: 33px;
	text-align: center;
	margin-bottom: -1%;
}

.msg-container button {
	font-size: 22px;
	font-weight: 700;
	text-align: center;
	padding: 10px 20px;
	border-radius: 50px;
	border: 4px solid #3AAEE0;
	background-color: #3AAEE0;
	color: white;
	cursor: default;
}

@media (max-width: 768px) {
	.top-container {
		background-position-x: -100px;
		background-position-y: -280px;
		height: 45vh;
	}

	.image-home img {
		margin-left: 16%;
	}
	
	.desc-home h1 {
		font-size: 16px;
		font-weight: 400;
	}
	
	.desc-home p {
		font-size: 12px;
		line-height: 20px;
	}
	
	.desc-home button {
		font-size: 10px;
		font-weight: 400;
		padding: 3px 3px;
		border-radius: 20px;
		text-align: center;
		border: 2px solid white;
	}

	.msg-container p {
		font-size: 20px;
		padding: 0% 5% 5% 5%;
	}
	
	.msg-container button {
		font-size: 16px;
		font-weight: 400;
		padding: 5px 5px;
		border-radius: 30px;
		border: 2px solid #3AAEE0;
	}
}