@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

.clientes-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FFF;
    padding: 8% 0% 8% 0%;
}

.clientes-container h1 {
    font-family: Montserrat, "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif !important;
    color: #1474b6 !important;
    font-size: 32px;
    font-weight: 700;
    line-height: 33px;
    text-align: center;
}

.img-container {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 20px;
    padding: 5% 15% 2% 15%;

    img {
        width: 100%;
        height: auto;
        border-radius: 8px;
    }
}

@media (max-width: 768px) {
    .clientes-container h1 {
        font-size: 16px;
        line-height: 14px;
    }

    .img-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;
        padding: 5% 15% 2% 15%;
    }
}