@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

* {
	font-family: Montserrat, "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif !important;
}

.fale-conosco-container {
    display: flex;
    background-color: #004364;
	padding: 7% 15% 5% 15%;
}

.fale-conosco-descricao, .fale-conosco-image-container {
    flex: 1
}

.fale-conosco-descricao h1 {
	font-size: 42px;
	font-weight: 700;
	letter-spacing: -1px;
	line-height: 45px;
	color: #FFF !important;
}

.fale-conosco-descricao p {
	text-align: justify;
	font-size: 20px;
	line-height: 27px;
	margin-top: 20px;
}

.fale-conosco-image-container img {
    width: 126%;
    height: 100%;
}

.fale-conosco-button button {
	font-size: 25px;
	font-weight: 700;
    text-align: center;
	padding: 10px 20px;
	border-radius: 50px;
	border: 4px solid white;
	background-color: #3AAEE0;
	color: white;
	cursor: pointer;
}

@media (max-width: 768px) {
	.fale-conosco-container {
		display: block;
	}

	.fale-conosco-descricao h1 {
		text-align: center;
		font-size: 22px;
		line-height: 25px;
	}
	
	.fale-conosco-descricao p {
		text-align: center;
		font-size: 16px;
		margin-top: -5px;
	}

	.fale-conosco-image-container img {
		width: 100%;
		height: 100%;
	}

	.fale-conosco-button button {
		font-size: 22px;
		font-weight: 400;
		padding: 3px 8px;
		border-radius: 40px;
		border: 2px solid white;
		margin-bottom: 10px;
	}
}