/* UNIMAKE DARK PALLET https://colorhunt.co/palette/167893 */
$dk-unimake-primary: #000;
$dk-unimake-secondary: #000;
$dk-unimake-darkblue: #0f4c75;
$dk-unimake-blue: #3282b8;
$dk-unimake-verylightblue: #bbe1fa;

$esmerald: #2ecc71;

.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

// .ant-radio-group {
// 	width: 100%;
// }
// .ant-radio-button-wrapper {
// 	border: none;
// 	width: 20%;
// }
// .ant-radio-button-wrapper:first-child {
// 	border: none;
// }
// .ant-radio-button-wrapper {
// 	border: none;
// }

.umk-slider-button {
	line-height: 0;
	position: absolute;
	top: 50%;
	display: block;
	width: 30px;
	height: 50px;
	padding: 0;
	transform: translate(0, -50%);
	cursor: pointer;
	border: none;
	outline: none;
	z-index: 999;
	font-size: 30px;
	color: #034563;
	background: transparent;
}

.umk-slider-blog-button {
	line-height: 0;
	position: absolute;
	top: 50%;
	display: block;
	width: 30px;
	height: 50px;
	padding: 0;
	transform: translate(0, -100%);
	cursor: pointer;
	border: none;
	outline: none;
	z-index: 999;
	font-size: 30px;
	color: #034563;
	background: transparent;
}

/* Paddings */
.pr-5 {
	padding-right: 5px;
}

.pr-10 {
	padding-right: 10px;
}

.pr-15 {
	padding-right: 15px;
}

.pl-5 {
	padding-left: 10px;
}

.pl-10 {
	padding-left: 10px;
}

.pl-15 {
	padding-left: 15px;
}

.main-footer {
	float: bottom;
}

.unimake-content {
	min-height: 50vh;
}

// Content
// Older 64px
.main-content {
	padding-top: 97px !important;
	overflow: hidden !important;
	min-height: 650px;
}

.main-content .content-area {
	padding: 10px 10px !important;
	margin: 8px 8px !important;
	transition: 0.3s;
}

.main-content-v3 .content-area {
	padding: 10px 10px !important;
	margin: 8px 8px !important;
	transition: 0.3s;
	min-height: 100% !important;
}

.content-area {
	padding: 6px !important;
}

.content-area-wcorner {
	padding: 10px 10px !important;
	margin: 8px 8px !important;
}

.text-center {
	text-align: center;
}

.center {
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.ant-popover-placement-bottom {
	padding-top: 30px !important;
	width: 65% !important;
}

.icon-default {
	font-size: 27px;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.whatsapp-button {
	color: #ffff !important;
	background-color: #128c7e !important;
	border-color: #128c7e !important;
	width: 95% !important;
}

.whatsapp-button > span {
	vertical-align: 7px !important;
	font-size: 1.5vh;
}

.wpp-icon {
	padding-right: 5px !important;
	font-size: 3.2vh;
}

@media only screen and (max-width: 768px) {
	.ant-popover-placement-bottom {
		padding-top: 65px !important;
		width: 95% !important;
		z-index: 99999 !important;
		margin-left: 100% !important;
	}
}

.md-chat-widget-btn-container .md-chat-widget-btn-wrapper:hover,
.md-chat-widget-btn-container {
	border-radius: 7px !important;
	background: #034563;
	box-shadow: 2px 2px 7px #000;
}

#md-app-widget.md-chat-widget-wrapper {
	bottom: 20px !important;
}

no-overflow .react-grid-Cell__value div {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: unset;
}

.image-container {
	position: relative;
	text-align: center;
}

.image-container > a {
	width: 263px;
}
.centered-text-onimage {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.blog-titulo-image {
	width: 100%;
	padding: 0 15px;
}

.rainbowGradient {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px;
	border-radius: 10px;
	background: linear-gradient(to left, #33326a, #48519f, #569ad9, #9ebe5d, #f1c40f, #e67e22, #e74c3c);
}

.form-item-umk {
	margin-top: 10px;
	margin-bottom: 10px;
}

.hide {
	display: none;
}

.hoverme:hover + .hide {
	display: block !important;
}

// ImageViewer
.cgseTX {
	z-index: 99999 !important;
}

.ant-card-bordered {
	border: 1px solid #d3d2d2;
}

.ant-card-head {
	border-bottom: 1px solid #d3d2d2;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th {	
	border-right: 1px solid #034563;
	border-left: 1px solid #034563;
	border-bottom: 1px solid #034563;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td {
	border-right: 1px solid #d3d2d2;
	border-left: 1px solid #d3d2d2;
	border-bottom: 1px solid #d3d2d2;
}

.ant-table .ant-table-container .ant-table-tbody .ant-table-cell p {
	padding: 0;
	text-align: center;
	color: #333;
}