// Media Queries
$mq-xsmall: "(max-width: 575px)";
$mq-small: "(max-width: 767px)";
$mq-medium: "(min-width: 768px) and (max-width: 991px)";
$mq-large: "(min-width: 992px) and (max-width: 1199px)";
$mq-xlarge: "(min-width: 1200px)";

// Font-Size
$base-font-size: 1em;

// Line-Height
$base-line-height: 1.5;
$header-line-height: 1.25;

.unimake-container-home {
	padding-top: 10px;
	margin: 0 2%;
	padding-bottom: 50px;

	//xs
	@media #{$mq-xsmall} {
	}

	//sm
	@media #{$mq-small} {
		margin: 0 2%;
	}

	//md
	@media #{$mq-medium} {
		margin: 0 4%;
	}

	//lg
	@media #{$mq-large} {
		margin: 0 6%;
	}

	//xlg
	@media #{$mq-xlarge} {
		margin: 0 14%;
	}
}

.unimake-container {
	padding-top: 15px;
	margin: 0 2%;
	padding-bottom: 50px;
	min-height: 100% !important;
	padding-bottom: 200px !important;

	//xs
	@media #{$mq-xsmall} {
	}

	//sm
	@media #{$mq-small} {
		margin: 0 2%;
	}

	//md
	@media #{$mq-medium} {
		margin: 0 4%;
	}

	//lg
	@media #{$mq-large} {
		margin: 0 6%;
	}

	//xlg
	@media #{$mq-xlarge} {
		margin: 0 14%;
	}
}

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
	display: none !important;
}

//xs
@media (max-width: 575px) {
	.visible-xs {
		display: block !important;
	}
	.row.visible-xs {
		display: flex !important;
		display: -webkit-flex !important;
		display: -ms-flexbox !important;
		display: -webkit-box !important;
		display: flex !important;
	}
	table.visible-xs {
		display: table !important;
	}
	tr.visible-xs {
		display: table-row !important;
	}
	th.visible-xs,
	td.visible-xs {
		display: table-cell !important;
	}
}

//sm
@media (min-width: 576px) and (max-width: 767px) {
	.visible-sm {
		display: block !important;
	}
	.row.visible-sm {
		display: flex !important;
		display: -webkit-flex !important;
		display: -ms-flexbox !important;
		display: -webkit-box !important;
		display: flex !important;
	}
	table.visible-sm {
		display: table !important;
	}
	tr.visible-sm {
		display: table-row !important;
	}
	th.visible-sm,
	td.visible-sm {
		display: table-cell !important;
	}
}

//md
@media (min-width: 768px) and (max-width: 991px) {
	.visible-md {
		display: block !important;
	}
	.row.visible-md {
		display: flex !important;
		display: -webkit-flex !important;
		display: -ms-flexbox !important;
		display: -webkit-box !important;
		display: flex !important;
	}
	table.visible-md {
		display: table !important;
	}
	tr.visible-md {
		display: table-row !important;
	}
	th.visible-md,
	td.visible-md {
		display: table-cell !important;
	}
}

//lg
@media (min-width: 992px) {
	.visible-lg {
		display: block !important;
	}
	.row.visible-lg {
		display: flex !important;
		display: -webkit-flex !important;
		display: -ms-flexbox !important;
		display: -webkit-box !important;
		display: flex !important;
	}
	table.visible-lg {
		display: table !important;
	}
	tr.visible-lg {
		display: table-row !important;
	}
	th.visible-lg,
	td.visible-lg {
		display: table-cell !important;
	}
}
@media (max-width: 576px) {
	.hidden-xs {
		display: none !important;
	}
}
@media (min-width: 576px) and (max-width: 767px) {
	.hidden-sm {
		display: none !important;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.hidden-md {
		display: none !important;
	}
}
@media (min-width: 992px) {
	.hidden-lg {
		display: none !important;
	}
}
