@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

* {
    font-family: Montserrat, "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif;
}

.produtos-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #FFF;
}

.produtos-title h1 {
    font-size: 36px;
    font-weight: 700;
    color: rgb(20, 116, 182) !important;
    padding: 3% 0% 2% 0%;
}

.produtos-grid {
    display: flex;
    flex-wrap: wrap;
}

.grid1,
.grid2,
.grid3,
.grid4,
.grid5,
.grid6,
.grid7 {
    display: flex;
    width: 100%;
    padding-bottom: 5%;
}

.details {
    width: 75%;
    height: 80%;
    padding: 0% 0% 0% 5%;
}

.gemini,
.unidanfe,
.danfeview,
.unico,
.uninfe,
.bi,
.mia,
.ebank,
.certificado,
.umessenger {
    display: flex;
    width: 40%;
    margin-bottom: 20px;
    box-sizing: border-box;
    margin: 2% 3% 0% 7%;

    img {
        width: 129px;
        height: 129px;
    }

    h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px
    }

    p {
        text-align: center;
        font-size: 16px;
        line-height: 16px;
        padding: 0% 10% 0% 5%;
    }

    button {
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        padding: 3px 25px;
        border-radius: 25px;
        background-color: transparent;
        cursor: pointer;
        margin-right: 10px;
    }
}

.gemini,
.unidanfe {
    h3 {
        color: #1474B6 !important;
    }

    p {
        color: #1474B6;
    }

    button {
        border: 3px solid #1474B6;
        color: #1474B6;

        a {
            color: #1474B6;
        }
    }
}

.danfeview {
    h3,
    p {
        color: #F68C47 !important;
    }

    button {
        border: 3px solid #F68C47;
        color: #F68C47;

        a {
            color: #F68C47;
        }
    }
}

.unico {
    h3,
    p,
    a {
        color: #3DA39D !important;
    }

    button {
        border: 3px solid #3DA39D;
        color: #3DA39D;
    }
}

.uninfe {
    h3,
    p {
        color: #3AB64B !important;
    }

    button {
        border: 3px solid #3AB64B;
        color: #3AB64B;

        a {
            color: #3AB64B;
        }
    }
}

.bi {
    h3,
    p {
        color: #333333 !important;
    }

    button {
        border: 3px solid #333333;
        color: #333333;
    }
}

.mia {
    h3,
    p {
        color: #2586CF !important;
    }

    button {
        border: 3px solid #2586CF;
        color: #2586CF;

        a {
            color: #2586CF;
        }
    }
}

.umessenger {
    h3,
    p {
        color: #1D2375 !important;
    }

    button {
        border: 3px solid #1D2375;
        color: #1D2375;

        a {
            color: #1D2375;
        }
    }
}

.ebank {
    h3,
    p {
        color: #38306C !important;
    }

    button {
        border: 3px solid #38306C;
        color: #38306C;

        a {
            color: #38306C;
        }
    }
}

.certificado {
    h3,
    p {
        color: #2B9074 !important;
    }

    button {
        border: 3px solid #2B9074;
        color: #2B9074;

        a {
            color: #2B9074;
        }
    }
}

@media (max-width: 768px) {
    .produtos-title h1 {
        font-size: 20px;
    }

    .grid1,
    .grid2,
    .grid3,
    .grid4,
    .grid5,
    .grid6,
    .grid7 {
        display: block;
    }

    .details {
        width: 100%;
        padding: 0% 0% 0% 5%;
    }

    .gemini,
    .unidanfe,
    .danfeview,
    .unico,
    .uninfe,
    .bi,
    .mia,
    .ebank,
    .certificado,
    .umessenger {
        padding-bottom: 10%;
        width: 80%;

        img {
            width: 145px;
            height: 145px;
        }

        h3 {
            font-size: 20px;
            line-height: 20px;
        }

        p {
            font-size: 16px;
            line-height: 16px;
        }

        button {
            font-size: 16px;
            font-weight: 700;
            text-align: center;
            padding: 3px 25px;
        }
    }

    .unidanfe,
    .danfeview {
        button {
            margin-top: 5px;
        }
    }

    .gemini,
    .ebank {
        button {
            margin-top: -5px;
        }
    }

    .certificado button {
        margin-top: -5px;
    }
}