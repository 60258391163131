@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

* {
    font-family: Montserrat, 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif;
}

.sobre-container {
    box-sizing: border-box;
    text-decoration: none solid rgb(0,0,0);
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    background-color: #B0CAD6;
    background-repeat: no-repeat;
    border-radius: 0px;
}

.descricao-container, .image-container {
    flex:1;
}

.descricao-container {
    padding: 6% 1% 6% 15%;
}

.descricao-container .sobre-title h1 {
    font-size: 38px;
    font-weight: 400;
    line-height: 120%;
    text-align: left;
    color: rgb(4, 35, 76) !important;
}

.sobre-title span {
    font-weight: 500;
}

.sobre-desc {
    padding: 1% 0% 1% 0%;
}

.sobre-desc p {
    font-size: 18px;
    color: #2C405B;
    color: rgb(4, 35, 76) !important;
    text-decoration: none solid rgb(4, 35, 76);
    text-align: justify;
    word-spacing: 0px;
}

.image-container {
    margin-right: 10%;
    margin-left: 2%;
}

.sobre-image img {
    width: 100%;
    height: 100%;
}

@media (max-width: 768px) {
    .sobre-container {
        display: block;
        padding: 1% 5% 1% 5%;
    }

    .descricao-container .sobre-title h1 {
        font-size: 26px;
        line-height: 110%;
    }
    
    .sobre-desc p {
        font-size: 16px;
    }

    .sobre-image img {
        width: 90%;
        height: 100%;
        margin: -8% 0% 0% 15%;
    }
}